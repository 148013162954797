import * as React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import fertilitaSvg from "../images/icona_fertilita.svg";
// import odontoiatriaSvg from "../images/icona_odontoiatria.svg";
import rinoplasticaSvg from "../images/icona_rinoplastica.svg";
// import bariatricaSvg from "../images/icona_bariatrica.svg";
import trapiantoSvg from "../images/icona_trapianto.svg";

import chatSvg from "../images/chat_icon.svg";
import homeSvg from "../images/home_icon.svg";
import worldSvg from "../images/world_icon.svg";

import handsSvg from "../images/hands_icon.svg";
import heartSvg from "../images/heart_icon.svg";
import ribbonSvg from "../images/ribbon_icon.svg";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NewsletterBanner from "../components/newsletterBanner";
import DestinationsRow from "../components/destinationsRow";

const query = graphql`
  query {
    destinations: allStrapiPage(filter: { sezione: { eq: "destinazioni" } }) {
      nodes {
        id
        anteprima_testo
        Titolo
        Permalink
        Contenuto
        contenuto
        categoria {
          Name
        }
        publication_date
        posizione_copertina
        Copertina {
          alternativeText
          localFile {
            absolutePath
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 90
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <SEO title="Cos’è il turismo medico - Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper overflow-hidden relative">
        <div className="color-overlay absolute w-full h-full bg-sterea-dark-blue bg-opacity-40 z-20"></div>

        <StaticImage
          src="../images/copertina_turismo_medico.jpg"
          style={{ position: "absolute" }}
          className="w-full h-full z-10"
          objectFit="cover"
          alt="Foto di un viaggiatore seduto in un aeroporto"
        />

        <div className="sterea-center-column h-3/4-screen z-20 flex justify-center items-center">
          <h1 className="w-11/12 text-white text-3xl lg:text-4xl leading-normal text-center font-bold">
            Cos’è il turismo medico
          </h1>
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min">
          <div className="sterea-content-container pt-12 lg:w-full">
            <p>
              Il Turismo Medico o Medical Tourism è un settore di grande
              tendenza in tutto il Mondo in grado di generare tantissime
              opportunità, innanzitutto, per i pazienti che grazie alla
              globalizzazione dell’offerta sanitaria e turistica possono
              accedere, sempre più facilmente, a cure specialistiche in Paesi
              diversi da quello di residenza, ad un costo significativamente
              inferiore. Le cure possono variare dagli interventi di chirurgia
              plastica, all' odontoiatria, ai trattamenti di infertilità, alla
              medicina preventiva o diagnostica, o semplicemente ai trattamenti
              di benessere.
            </p>
            <h2 className="mb-0 text-center lg:text-left">
              Il consulente medicale
            </h2>
          </div>
          <div className="sterea-content-container flex flex-wrap justify-center items-center mb-12 lg:w-full">
            <div className="w-2/3 mb-12 lg:mb-0 lg:w-1/2 lg:pr-24">
              <StaticImage
                src="../images/laura_e_stella_in_ufficio.png"
                placeholder="blurred"
                layout="fullWidth"
                objectFit="cover"
                alt="Immagine presa da un video dove è presente Laura Serzentaite, co-fondatrice Sterea all'interno di uno studio medico"
              />
            </div>
            <p className="w-full lg:w-1/2">
              Fare un viaggio medicale all'estero comporta un'organizzazione da
              pianificare con molta attenzione. Noi di Sterea, come consulenti
              medicali, ci prendiamo cura di voi! Rispondiamo a tutte le vostre
              domande, vi aiutiamo a coordinare il vostro intervento, a
              prenotare gli alloggi, organizzare i trasferimenti, ci assicuriamo
              che il vostro viaggio sia sicuro e confortevole affinchè possiate
              concentrarvi su voi stessi e realizzare i vostri sogni nella
              massima tranquillità.
            </p>
          </div>
          <div className="sterea-content-container flex flex-wrap justify-center items-center mb-12 lg:w-full">
            <p className="order-2 lg:order-1 w-full lg:w-1/2">
              Il nostro punto di forza sta nel proporre Cliniche che abbiamo
              visionato e selezionato personalmente, assicurandoci che le
              strutture siano moderne e confortevoli, che i materiale usati
              siano di ultimissima generazione e soprattutto assicurandoci
              dell'elevatissima professionalità degli specialisti e di tutto il
              personale.
            </p>
            <div className="order-1 lg:order-2 w-2/3 mb-12 lg:mb-0 lg:w-1/2 lg:pl-24">
              <StaticImage
                src="../images/laura_e_stella_in_sala.png"
                placeholder="blurred"
                layout="fullWidth"
                objectFit="cover"
                alt="Immagine presa da un video dove è presente Laura Serzentaite, co-fondatrice Sterea all'interno di uno studio medico"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="sterea-center-column-wrapper py-12 px-min bg-sterea-yellow">
        <div className="sterea-center-column flex flex-col justify-center">
          <h2 className="text-center">Come funziona</h2>
          <div className="flex flex-wrap pt-12">
            <div className="w-full lg:w-1/3 mb-12 lg:mb-0 lg:px-3 flex flex-col items-center text-center">
              <img
                src={chatSvg}
                className="mb-6 h-16"
                alt="Icona di una chat, che rappresenta la fase di consultazione con Sterea"
              />
              <p>
                <span className="font-bold">1.</span> Decideremo insieme, in
                base alle vostre esigenze, quali trattamenti sono più adatti a
                voi.
              </p>
            </div>
            <div className="w-full lg:w-1/3 mb-12 lg:mb-0 lg:px-3 flex flex-col items-center text-center">
              <img
                src={worldSvg}
                className="mb-6 h-16"
                alt="Icona di un mondo, che rappresenta il viaggio all'estero con Sterea"
              />
              <p>
                <span className="font-bold">2.</span> Un assistente personale
                che parla italiano vi accoglierà in aeroporto, vi affiancherà
                costantemente per tutto il tempo che sarete in Clinica e nei
                momenti liberi, se necessario, per garantire un'esperienza
                all'estero senza preoccupazioni.
              </p>
            </div>
            <div className="w-full lg:w-1/3 lg:px-3 flex flex-col items-center text-center">
              <img
                src={homeSvg}
                className="mb-6 h-16"
                alt="Icon della casa, che rappresenta il ritorno a casa dopo l'intervento con Sterea"
              />
              <p>
                <span className="font-bold">3.</span> Restiamo in contatto
                telefonico e rimaniamo a vostra disposizione per qualsiasi
                aiuto.
              </p>
            </div>
          </div>
          {/* <div className="flex justify-center">
            <div className="sterea-blue-purple-button">Scopri di più</div>
          </div> */}
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min py-12">
          <p className="text-center pb-9">
            Ti aiutiamo, dalla consultazione con lo specialista, alla
            prenotazione dell'hotel, ai trasferimenti. Un nostro consulente ti
            seguirà e ti accompagnerà per tutto il viaggio, tu dovrai solo
            pensare a te e goderti tutta l’esperienza in serenità.
          </p>
          <h2 className="text-center mb-6">Gli interventi</h2>
          <div className="flex flex-wrap justify-center">
            <div className="sterea-procedure-card-wrapper">
              <Link
                to={`/interventi/chirurgia-estetica`}
                className="sterea-procedure-card"
              >
                <img
                  src={rinoplasticaSvg}
                  className="mb-6 h-32"
                  alt="Icon di un naso non dritto, che rappresenta un intervento di rinoplastica, di chirurgia estetica/plastica"
                />
                <h4 className="mt-6 font-bold text-center">
                  Chirurgia Estetica
                </h4>
              </Link>
            </div>
            {/* <div className="sterea-procedure-card-wrapper">
              <div className="sterea-procedure-card">
                <img
                  src={bariatricaSvg}
                  className="mb-6 h-32"
                  alt="Icona di uno stomaco che viene ridotto, che rappresenta un intervento di chirurgia bariatrica"
                />
                <h4 className="mt-6 font-bold text-center">
                  Chirurgia Bariatrica
                </h4>
              </div>
            </div> */}
            <div className="sterea-procedure-card-wrapper">
              <Link
                to={`/interventi/trapianto-capelli`}
                className="sterea-procedure-card"
              >
                <img
                  src={trapiantoSvg}
                  className="mb-6 h-32"
                  alt="Icona di una testa piena di capelli invece che calva, che rappresenta un intervento di trapianto di capelli"
                />
                <h4 className="mt-6 font-bold text-center">
                  Trapianto Capelli
                </h4>
              </Link>
            </div>
            <div className="sterea-procedure-card-wrapper">
              <Link
                to={`/interventi/fertilita`}
                className="sterea-procedure-card"
              >
                <img
                  src={fertilitaSvg}
                  className="mb-6 h-32"
                  alt="Icona della pancia con un bambino dentro, all'interno di un cuore, che rappresenta gli interventi di IVF e fertilità"
                />
                <h4 className="mt-6 font-bold text-center">Fertilità</h4>
              </Link>
            </div>
            {/* <div className="sterea-procedure-card-wrapper">
              <div className="sterea-procedure-card">
                <img
                  src={odontoiatriaSvg}
                  className="mb-6 h-32 w-32"
                  alt="Icona dei denti di una bocca, che rappresenta un intervento di odontoiatria"
                />
                <h4 className="mt-6 font-bold text-center">Odontoiatria</h4>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <DestinationsRow destinations={data.destinations.nodes} />

      <div className="sterea-center-column-wrapper py-12 px-min bg-gray-100">
        <div className="sterea-center-column">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/3 px-3 mb-12 lg:mb-0 flex flex-col items-center">
              <img src={handsSvg} className="mb-6 h-16" />
              <p className="text-center">
                Per guidare e agevolare le vostre scelte, per annullare le
                distanze e le barriere linguistiche che si possono incontrare
                nel decidere di affrontare un intervento all'estero.
              </p>
            </div>
            <div className="w-full lg:w-1/3 px-3 mb-12 lg:mb-0 flex flex-col items-center">
              <img src={ribbonSvg} className="mb-6 h-16" />
              <p className="text-center">
                Contattare alcuni ospedali internazionali può essere difficile a
                causa delle differenze di fuso orario, delle barriere
                linguistiche e delle differenze culturali. Con Sterea avete la
                possibilità di superare tutto questo perchè disponiamo già di un
                sistema per rendere agevole il vostro viaggio medicale.
              </p>
            </div>
            <div className="w-full lg:w-1/3 px-3 flex flex-col items-center">
              <img src={heartSvg} className="mb-6 h-16" />
              <p className="text-center">
                Saremo sempre presenti per dissipare qualsiasi dubbio, dal primo
                consulto ai controlli post-operatori, perchè il nostro
                obbiettivo è la Vostra salute e la Vostra sicurezza.
              </p>
            </div>
          </div>
        </div>
      </div>

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
